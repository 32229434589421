<template>
  <g>
    <handle-gradient
      :doorLeftWidth="doorLeftWidth"
      :handleX="handleX"
      :handleY="handleY"
    />
    <rect
      filter="url(#handleshadow)"
      id="handle"
      class="handle"
      :x="handleX"
      :y="handleY"
      :width="handleWidth"
      :height="handleHeight"
    />
  </g>
</template>

<script>
import HandleGradient from '@/components/door/model-handle-gradient';

export default {
  components: {
    HandleGradient
  },
  props: {
    doorLeftWidth: {
      type: Number,
      default: 0
    },
    handleX: {
      type: Number,
      default: 0
    },
    handleY: {
      type: Number,
      default: 0
    },
    isBlack: {
      type: Boolean,
      default: false
    },
    handleWidth: {
      type: Number,
      default: 0
    },
    handleHeight: {
      type: Number,
      default: 0
    }
  }
};
</script>
